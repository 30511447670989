<template>
  <interview-layout :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div class="">
      <!-- title above form card -->
      <h1 class="px-4 pb-3 fade-in-up-enter-active animation-slowest max-100 text-primary">{{lead.address.eligible ? 'Gratis energieadvies' : 'Energieadvies'}}</h1>
      <h5 class="px-4 pb-3 fade-in-up-enter-active animation-slowest delay-200 max-100" v-show="lead.address.eligible">Uw gemeente stelt gratis advies beschikbaar</h5>
      <div class="d-flex flex-column flex-lg-row justify-content-center">

        <!-- form 1 card -->
        <base-card inner-class="container-narrow w-100" color="alternate" :outer-padding="true">
          <base-form @submit="onSubmit">

            <div v-show="lead.address.eligible" class="small mb-4">
                <checkmark-item class="mb-2">
                  Uw gemeente stelt een gratis energieadvies beschikbaar voor <variable-pill>{{ lead.address.Street }} {{ lead.address.StreetNumber }}</variable-pill>. Dat verplicht u tot niets.
                </checkmark-item>
                <checkmark-item class="mb-2">
                  Een energieadviseur komt dan bij u thuis langs.
                </checkmark-item>
                <checkmark-item class="mb-2">
                  Deze adviseur legt u uit hoe u uw huis kunt verduurzamen. U krijgt een rapport met een advies welke maatregelen worden aangeraden. In dit rapport staat ook hoeveel geld u kunt besparen.
                </checkmark-item>
            </div>

            <!-- inputs -->
            <base-input
              type="radiobuttons" :options="yesNo"
              name="Consent"
              v-model:value="lead.advice.ConsentForContact" :validator="v$.advice.ConsentForContact">
              Wilt u een gratis energieadvies ontvangen?
            </base-input>
            <issue-feedback :issue="qualStore.global.rules.ConsentForContact" />

            <base-input
              class="mt-4"
              type="radiobuttons" :options="yesNo"
              name="HelpWithQuotes"
              v-model:value="lead.advice.HelpWithQuotes" :validator="v$.advice.HelpWithQuotes">
              Wilt u hulp bij het aanvragen van offertes om het advies uit te voeren?
            </base-input>
            <issue-feedback :issue="qualStore.global.rules.HelpWithQuotes" />

            <base-input
              class="mt-4"
              type="radiobuttons" :options="yesNo"
              name="HelpWithVHF"
              v-model:value="lead.advice.HelpWithVHF" :validator="v$.advice.HelpWithVHF">
              Wilt u hulp bij het aanvragen van subsidie uit het volkshuisvestingsfonds (VHF)?
            </base-input>
            <issue-feedback :issue="qualStore.global.rules.HelpWithVHF" />

          </base-form>
        <!-- form card end -->
        </base-card>
      
      <!-- cards wrapper end -->
      </div>
      <button-row class="mt-2 flex-row-reverse">
        <base-button class="ms-auto" icon="bi-arrow-right" @click="onSubmit" :disabled="v$.advice.$invalid">Volgende</base-button>
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"
  import BaseCard from '@/components/BaseCard.vue'
  import BaseForm from "@/components/BaseForm.vue"
  import BaseInput from "@/components/BaseInput.vue"
  import BaseButton from "@/components/BaseButton.vue"
  import ButtonRow from "@/components/ButtonRow.vue"
  import VariablePill from "@/components/VariablePill.vue"
  import CheckmarkItem from "@/components/CheckmarkItem.vue"
  import IssueFeedback from "@/components/IssueFeedback.vue"

  import { useAllStores } from "@/helpers/UseAllStores"
  import { CommonComposable } from "@/helpers/CommonComposable"
  import { FlowService } from "@/services/FlowService"

  import { LeadDataService } from "@/services/LeadDataService"

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      BaseForm,
      BaseInput,
      BaseButton,
      ButtonRow,
      VariablePill,
      CheckmarkItem,
      IssueFeedback
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    data() {
      return {
        yesNo: [
          {
            label: 'Ja',
            value: true
          },
          {
            label: 'Nee',
            value: false
          },
        ]
      };
    },
    computed: {
      showHoaContactForm() {
        return (this.lead.ownership.HoaPermission == true && this.lead.ownership.HoaMember == true);
      }
    },
    methods: {
      touchAndValidate() {
        this.v$.ownership.$touch();
        return !this.v$.ownership.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
      onPrev() {
        FlowService.pushPrev(this);
      }
    },
    beforeRouteLeave() {
      LeadDataService.pushRemoteData(this.uiStore.uuid, true);
    }
  }
</script>

<style lang="scss">
</style>