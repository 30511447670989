<template>
  <span class="variable-pill">
    <slot></slot>
  </span>
</template>

<style scoped>
.variable-pill {
  text-decoration-line: underline;
  text-decoration-style: dotted;
  text-underline-offset: 3px;
}
</style>