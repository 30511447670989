<template>
  <interview-layout :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div class="">
      <!-- title above form card -->
      <h1 class="px-4 pb-3 fade-in-up-enter-active animation-slowest max-100 text-primary">Subsidieregelingen</h1>
      <h5 class="px-4 pb-3 fade-in-up-enter-active animation-slowest delay-200 max-100">Toekomstige subsidie uit Bij Begun</h5>
      <div class="d-flex flex-column flex-lg-row justify-content-center">

        <!-- form 1 card -->
        <base-card inner-class="container-narrow w-100" color="alternate" :outer-padding="true">
          <base-form @submit="onSubmit">

            <div class="small mb-4">
                <checkmark-item class="mb-2" icon="info-circle-fill">
                  Maatregel 29 van <a href="https://www.nijbegun.nl/themas/isolatieaanpak/" target="_blank">Nij Begun</a> wordt momenteel uitgewerkt.
                </checkmark-item>
                <checkmark-item class="mb-2" icon="info-circle-fill">
                  Woningeigenaren in de provincie Groningen en Noord-Drenthe kunnen vanaf het eerste kwartaal van 2025 subsidie aanvragen om hun woning te isoleren.
                </checkmark-item>
                <checkmark-item class="mb-2" icon="info-circle-fill">
                  Er zullen speciale regelingen gelden voor bewoners met een inkomen tot 140% van het wettelijk minimumloon.
                </checkmark-item>
            </div>

            
            <!-- card with 125% of social minimum -->
            <base-card inner-class="small">
              <strong class="fw-500">
                140% van het minimumloon is in 2024 ca.:
              </strong>
              <div class="mt-3 fw-500">
                <span class="fw-600 fs-5 text-info">
                  {{ monthlyWage140Pct }}
                </span>
                per maand (bruto)
              </div>
              <div class="mt-3 fw-500">
                <span class="fw-600 fs-5 text-info">
                  {{ annualWage140Pct }}
                </span>
                per jaar (bruto)
              </div>
              <div class="mt-3">
                <i>Dit is het totale <strong class="fw-600">bruto</strong> inkomen van uw <strong class="fw-600">huishouden</strong> bedragen, exclusief vakantiegeld.</i>
              </div>
            </base-card>

            <base-input
              class="mt-5"
              type="radiobuttons" :options="moreLess"
              name="IncomeOver140pct"
              v-model:value="lead.financing.maatregel29.IncomeOver140pct" :validator="v$.financing.maatregel29.IncomeOver140pct">
              Is uw bruto inkomen hoger of lager dan de bovenstaande bedragen?
            </base-input>

            <div class="mt-3 small">
              <i><strong class="fw-600">Weet u het niet precies?</strong> Maak dan een schatting. We kijken later nogmaals naar uw inkomen v&oacute;&oacute;r we overgaan tot een offici&euml;le aanvraag.</i>
            </div>

          </base-form>
        <!-- form card end -->
        </base-card>
      
      <!-- cards wrapper end -->
      </div>
      <button-row class="mt-2 flex-row-reverse">
        <base-button class="ms-auto" icon="bi-arrow-right" @click="onSubmit" :disabled="v$.financing.maatregel29.$invalid">Volgende</base-button>
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"
  import BaseCard from '@/components/BaseCard.vue'
  import BaseForm from "@/components/BaseForm.vue"
  import BaseInput from "@/components/BaseInput.vue"
  import BaseButton from "@/components/BaseButton.vue"
  import ButtonRow from "@/components/ButtonRow.vue"
  // import VariablePill from "@/components/VariablePill.vue"
  import CheckmarkItem from "@/components/CheckmarkItem.vue"
  // import IssueFeedback from "@/components/IssueFeedback.vue"

  import { useAllStores } from "@/helpers/UseAllStores"
  import { CommonComposable } from "@/helpers/CommonComposable"
  import { FlowService } from "@/services/FlowService"
  import { civilStatusOptions, ageBrackets, getSocialMinimum } from "@/services/SocialMinimumService"
  import { getAnnualMinimumWage, getMonthlyMinimumWage } from "@/helpers/MinimumWage"

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      BaseForm,
      BaseInput,
      BaseButton,
      ButtonRow,
      // VariablePill,
      CheckmarkItem,
      // IssueFeedback
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    data() {
      return {
        yesNo: [
          {
            label: 'Ja',
            value: true
          },
          {
            label: 'Nee',
            value: false
          },
        ],
        yesNoDontKnow: [
          {
            label: 'Ja',
            value: 'Ja'
          },
          {
            label: 'Nee',
            value: 'Nee'
          },
          {
            label: 'Weet ik niet',
            value: 'Weet ik niet'
          }
        ],
        moreLess: [
          {
            label: '- Lager',
            value: false
          },
          {
            label: 'Hoger +',
            value: true
          },
        ],
        civilStatusOptions: civilStatusOptions,
        ageBrackets: ageBrackets
      };
    },
    computed: {
      socialMinimum() {
        const nipFields = this.lead.financing.nip;
        return getSocialMinimum(nipFields.AgeBracket, nipFields.AgeBracketPartner, nipFields.CivilStatus,  nipFields.HasChildren);
      },
      socialMinimum125() {
        // multiply by 1.25, round to 2 decimals, replace dot with comma
        return (this.socialMinimum * 1.25).toFixed(2).replace('.', ',');
      },
      monthlyWage140Pct() {
        let maxWage = getMonthlyMinimumWage(2024, 2, 1.4, true, 0);
        return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(maxWage);
      },
      annualWage140Pct() {
        let maxWage = getAnnualMinimumWage(2024, 1.4, true, 0);
        return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(maxWage);
      }
    },
    methods: {
      touchAndValidate() {
        this.v$.ownership.$touch();
        return !this.v$.ownership.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
      onPrev() {
        FlowService.pushPrev(this);
      }
    }
  }
</script>

<style lang="scss">
</style>