<template>
  <interview-layout :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div class="">
      <!-- title above form card -->
      <h1 class="px-4 pb-3 fade-in-up-enter-active animation-slowest max-100 text-primary">SNN subsidie</h1>
      <h5 class="px-4 pb-3 fade-in-up-enter-active animation-slowest delay-200 max-100">Verduurzaming en Verbetering</h5>
      <div class="d-flex flex-column flex-lg-row justify-content-center">

        <!-- form 1 card -->
        <base-card inner-class="container-narrow w-100" color="alternate" :outer-padding="true">
          <base-form @submit="onSubmit">

            <!-- <div class="small mb-4">
                <checkmark-item class="mb-2">
                  Het Samenwerkingsverband Noord-Nederland (SNN) heeft subsidieregelingen voor woningeigenaren die in het aardbevingsbebied wonen of erkende bevingsschade hebben.
                </checkmark-item>
                <checkmark-item class="mb-2">
                  Middels deze vragen controleren we of u in aanmerking komt voor een aanvraag.
                </checkmark-item>
            </div> -->

            <!-- inputs -->
            <base-input
              type="radiobuttons" :options="yesNoDontKnow"
              name="Consent"
              v-model:value="lead.financing.snnSubsidy10k.InVersterking" :validator="v$.financing.snnSubsidy10k.InVersterking">
              Is uw woning onderdeel van het versterkingsprogramma van Nationaal Coördinator Groningen?
            </base-input>

            <!-- section SNN 10000 euro -->
            <div v-show="showSubsididyRequestedQuestions" class="fade-in-up-enter-active animation-slowest delay-200">
              <h6 class="pb-3 mt-5 max-100">Subsidie Verduurzaming en Verbetering Groningen – &euro;10.000</h6>

              <div class="small mb-5">
                <checkmark-item class="mb-2" :icon-color="lead.address.eligible ? 'success' : 'warning'">
                  {{ lead.address.eligible 
                    ? 'Uw woning valt binnen het aangewezen postcodegebied.'
                    : 'Valt uw postcode binnen het aangewezen postcodegbied?'}}
                </checkmark-item>
                <checkmark-item class="mb-2" :icon-color="lead.financing.snnSubsidy10k.InVersterking == 'Nee' ? 'success' : 'warning'">
                  {{ lead.financing.snnSubsidy10k.InVersterking == 'Nee'
                    ? 'Uw woning valt niet binnen het versterkingsprogramma van NCG.'
                    : 'En valt uw woning niet binnen het versterkingsprogramma van NCG?' }}
                </checkmark-item>
                <p>
                  Onder deze voorwaarden komt u waarschijnlijk in aanmerking voor deze subsidie die u kunt besteden aan verduurzamende of verbeterende maatregelen aan uw woning of bijgebouw. Meer informatie vind u op 
                  <a href="https://www.snn.nl/subsidies-voor-particulieren/subsidie-verduurzaming-en-verbetering-groningen-eu-10000" target="_blank">de website van het SNN</a>.
                </p>
              </div>

              <base-input
                type="radiobuttons" :options="yesNoDontKnow"
                name="Consent"
                v-model:value="lead.financing.snnSubsidy10k.RequestedSnn10k" :validator="v$.financing.snnSubsidy10k.RequestedSnn10k">
                Is er eerder de subsidie Verduurzaming en Verbetering Groningen (&euro;10.000) aangevraagd voor uw woning?
              </base-input>
            </div>

          </base-form>
        <!-- form card end -->
        </base-card>
      
      <!-- cards wrapper end -->
      </div>
      <button-row class="mt-2 flex-row-reverse">
        <base-button class="ms-auto" icon="bi-arrow-right" @click="onSubmit" :disabled="v$.financing.snnSubsidy10k.$invalid">Volgende</base-button>
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"
  import BaseCard from '@/components/BaseCard.vue'
  import BaseForm from "@/components/BaseForm.vue"
  import BaseInput from "@/components/BaseInput.vue"
  import BaseButton from "@/components/BaseButton.vue"
  import ButtonRow from "@/components/ButtonRow.vue"
  // import VariablePill from "@/components/VariablePill.vue"
  import CheckmarkItem from "@/components/CheckmarkItem.vue"
  // import IssueFeedback from "@/components/IssueFeedback.vue"

  import { useAllStores } from "@/helpers/UseAllStores"
  import { CommonComposable } from "@/helpers/CommonComposable"
  import { FlowService } from "@/services/FlowService"

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      BaseForm,
      BaseInput,
      BaseButton,
      ButtonRow,
      // VariablePill,
      CheckmarkItem,
      // IssueFeedback
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    data() {
      return {
        yesNo: [
          {
            label: 'Ja',
            value: true
          },
          {
            label: 'Nee',
            value: false
          },
        ],
        yesNoDontKnow: [
          {
            label: 'Ja',
            value: 'Ja'
          },
          {
            label: 'Nee',
            value: 'Nee'
          },
          {
            label: 'Weet ik niet',
            value: 'Weet ik niet'
          }
        ]
      };
    },
    computed: {
      showSubsididyRequestedQuestions() {
        return (this.lead.financing.snnSubsidy10k.InVersterking !== null && this.lead.financing.snnSubsidy10k.InVersterking !== 'Ja');
      }
    },
    methods: {
      touchAndValidate() {
        this.v$.ownership.$touch();
        return !this.v$.ownership.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
      onPrev() {
        FlowService.pushPrev(this);
      }
    }
  }
</script>

<style lang="scss">
</style>