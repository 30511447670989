<template>
  <interview-layout>
    <!-- inner wrapper used for centering -->
    <div class="container w-100 d-flex flex-column">

      <div class="submit-result-indicator">
        <div class="submit-message">
          <div class="text-success pulse-opacity-infinite" v-show="busy">
            <i class="bi bi-arrow-down-up me-2"></i>
            Resultaat opslaan...
          </div>
          <div class="text-success" v-show="resultSubmitted">
            <i class="bi bi-check-lg me-2"></i>
            Opgeslagen!
          </div>
          <div class="text-danger" v-show="!busy && !resultSubmitted">
            <i class="bi bi-exclamation-triangle-fill me-2"></i>
            Er is iets fout gegaan!
            <span v-if="uiStore.uuid">
              U kunt proberen de pagina te
              <base-button
                variant="danger"
                class="ms-2 d-inline-block"
                icon="bi-arrow-clockwise"
                icon-position="start"
                @click="reloadPage">
                herladen
            </base-button>
            </span>
          </div>
        </div>
      </div>

      <!-- title above form card -->
      <h1 class="px-4 fade-in-up-enter-active animation-slowest max-100">
        <span :class="{'text-primary': overallStatus !== 'all_disqualified', 'text-danger': overallStatus == 'all_disqualified', }">
          {{mainTitle}}
        </span>
      </h1>
      <h5 class="px-4 fade-in-up-enter-active animation-slowest delay-200 max-100" v-html="subTitle"></h5>

      <div v-if="anyQualified && resultSubmitted" class="mx-n4 mx-sm-0 px-sm-4 mt-4 mb-n4 w-sm-100 d-flex flex-column gap-3 fw-500">
        <base-card
          class="mt-2 position-relative fade-in-up-enter-active animation-slowest text-white delay-300"
          inner-class="py-3 pb-2"
          style="z-index: 3;"
          color="success"
          :opaque="true"
          :bubble-bottom-left="true"
        >
          <h6>Hoe nu verder?</h6>
        </base-card>
        <base-card
          class="position-relative fade-in-up-enter-active animation-slowest delay-400"
          inner-class="py-3 pb-3"
          style="z-index: 3;"
          color="light"
          :opaque="true"
          :bubble-bottom-left="true"
        >
          <span>
            <span class="text-primary me-2">1.</span>
            Onze adviseurs nemen contact op om een afspraak te maken voor een bezoek.
          </span>
        </base-card>
        <base-card
          class="position-relative fade-in-up-enter-active animation-slowest delay-500"
          inner-class="py-3 pb-3"
          style="z-index: 3;"
          color="light"
          :opaque="true"
          :bubble-bottom-left="true"
        >
          <span>
            <span class="text-primary me-2">2.</span>
            Wij komen bij u thuis langs om uw <variable-pill>wensen</variable-pill>, <variable-pill>financiele mogelijkheden</variable-pill>, <variable-pill>subsidies</variable-pill> en <variable-pill>bouwtechnische situatie</variable-pill> in kaart te brengen.
          </span>
        </base-card>
        <base-card
          class="position-relative fade-in-up-enter-active animation-slowest delay-600"
          inner-class="py-3 pb-3"
          style="z-index: 3;"
          color="light"
          :opaque="true"
          :bubble-bottom-left="true"
        >
          <span>
            <span class="text-primary me-2">3.</span>
            U ontvangt een <variable-pill>rapport</variable-pill> met aangeraden maatregelen en een verwachting van uw besparing.
          </span>
        </base-card>
        <base-card
          class="position-relative fade-in-up-enter-active animation-slowest delay-700"
          inner-class="py-3 pb-3"
          style="z-index: 3;"
          color="light"
          :opaque="true"
          :bubble-bottom-left="true"
        >
          <span>
            <span class="text-primary me-2">4.</span>
            U kiest welke maatregelen u wilt laten uitvoeren.
          </span>
        </base-card>
        <base-card
          class="position-relative fade-in-up-enter-active animation-slowest delay-800"
          inner-class="py-3 pb-3"
          style="z-index: 3;"
          color="light"
          :opaque="true"
          :bubble-bottom-left="true"
        >
          <span>
            <span class="text-primary me-2">5.</span>
            {{ lead.address.eligible ? 'De gemeente heeft subsidie voor het verduurzamen van uw woning. Wij helpen u bij de subsidieaanvraag.' : 'Als er subsidiemogelijkheden beschikbaar zijn helpen we u bij de subsidieaanvraag.' }}
          </span>
        </base-card>
      </div>
      
      <transition name="fade-in-up">
        <div class="px-4 mt-5 ms-auto" v-class="{'opacity-0': !(!busy || resultSubmitted)}">
          <return-to-portal-button v-if="lead.meta.ReturnUrl" text-align="end" />
        </div>
      </transition>

      <transition name="fade-in-up">
        <div class="call-request-container px-4 mt-5 ms-auto" v-show="!anyQualified && !lead.meta.ReturnUrl">
          <call-request-card />
        </div>
      </transition>

      <button-row class="mt-4">
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue";
  import BaseCard from '@/components/BaseCard.vue';
  import BaseButton from "@/components/BaseButton.vue";
  import ButtonRow from "@/components/ButtonRow.vue";
  // import QualIssueSummary from "@/components/QualIssueSummary.vue";
  import CallRequestCard from "@/components/CallRequestCard.vue";
  // import AppointmentScheduler from "@/components/AppointmentScheduler.vue";
  import ReturnToPortalButton from "@/components/ReturnToPortalButton.vue";
  import VariablePill from "@/components/VariablePill.vue";

  import { useAllStores } from "@/helpers/UseAllStores";
  import { FlowService } from "@/services/FlowService";
  import { useQualificationStore, enabledSections } from '@/store/QualificationStore';
  import PushWithParams from "@/helpers/PushWithParams";
  import { StripQuery } from "@/helpers/StripQuery";

  import { filter } from 'lodash';
  import { LeadDataService } from "@/services/LeadDataService";

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      BaseButton,
      ButtonRow,
      // QualIssueSummary,
      CallRequestCard,
      // AppointmentScheduler,
      ReturnToPortalButton,
      VariablePill
    },
    setup() {
      let qualStore = useQualificationStore();
      return {...useAllStores(), qualStore};
    },
    data() {
      return {
        expandedIssueSummary: '',
        busy: false,
        resultSubmitted: false,
        showAppointmentScheduler: false
      }
    },
    computed: {
      anyQualified() {
        return (this.overallStatus == 'all_qualified' || this.overallStatus == 'partially_qualified');
      },
      mainTitle() {
        if (this.overallStatus == 'all_disqualified') {
          return 'We beoordelen uw aanvraag';
        } else if (this.overallStatus == 'all_qualified' || this.overallStatus == 'partially_qualified') {
          if (this.busy) {
            return 'U wordt aangemeld';
          } else {
            return 'U bent aangemeld';
          }
        } else if (this.overallStatus == 'none_qualified_and_incomplete') {
          return 'Er ontbreekt nog informatie';
        }
        return 'Resultaten';
      },
      subTitle() {
        if (this.overallStatus == 'all_disqualified') {
          return 'Onze adviseurs beoordelen uw situatie op geschiktheid.';
        } else if (this.overallStatus == 'all_qualified') {
          if (this.lead.address.eligible) {
            return 'voor een gratis energieadvies.';
          } else {
            return 'voor een energieadvies.';
          }
        } else if (this.overallStatus == 'partially_qualified') {
          return 'Onze adviseurs beoordelen uw situatie op geschiktheid.';
        } else if (this.overallStatus == 'none_qualified_and_incomplete') {
          return 'Onze adviseurs beoordelen uw situatie op geschiktheid.';
        }
        return 'Beantwoord alle vragen of neem contact op!';
      },

      anySummaryExpanded() {
        return this.expandedIssueSummary !== '' && this.expandedIssueSummary !== ' ' && this.expandedIssueSummary;
      },
      overallStatus() {
        let qualifiedCount = 0;
        let disqualifiedCount = 0;
        let totalCount = Object.keys(this.allSections).length;
        for (const key in this.allSections) {
          let section = this.allSections[key];
          if (section.status == 'qualified') {
            qualifiedCount++;
          } else if (section.status == 'disqualified') {
            disqualifiedCount++;
          }
        }
        if (disqualifiedCount == totalCount && totalCount > 0) {
          return 'all_disqualified';
        } else if (qualifiedCount == totalCount) {
          return 'all_qualified';
        } else if (qualifiedCount > 0) {
          return 'partially_qualified';
        } else if ((qualifiedCount + disqualifiedCount) < totalCount) {
          return 'none_qualified_and_incomplete';
        }
        return 'unknown';
      },
      allSections() { // all checked interests
        return enabledSections.value;
      },
      qualifiedSections() { // qualified sections only
        return filter(this.qualStore.sections, (section) => {
          return section.qualified == true;
        });
      }
    },
    methods: {
      toggleIssueSummary(sectionKey) {
        this.expandedIssueSummary = this.expandedIssueSummary == sectionKey ? '' : sectionKey;
      },
      otherSummaryExpanded(sectionKey) {
        return this.anySummaryExpanded && this.expandedIssueSummary !== sectionKey;
      },
      onNavigateToIssueView(viewName) {
        PushWithParams(
          this.$router,
          this.$route,
          {
            name: viewName,
            query: { 'from_result': 'true' }
          },
          true);
      },
      toggleAppointmentScheduler() {
        this.showAppointmentScheduler = !this.showAppointmentScheduler;
      },

      submitResult() {
        this.busy = true;
      
        LeadDataService.pushRemoteData(this.uiStore.uuid, true)
        .then(() => {
          this.resultSubmitted = true;
        })
        .catch(() => {
          this.resultSubmitted = false;
          console.log('Failed to submit result.');
        })
        .then(() => {
          this.busy = false;
        });
      },

      onPrev() {
        FlowService.pushPrev(this);
      },
      sectionOverviewCardColor(section) {
        if (section.status == 'qualified') {
          return 'success';
        } else if (section.status == 'disqualified') {
          return 'danger';
        } else {
          return 'secondary';
        }
      },
      reloadPage() {
        window.location.reload();
      }
    },
    beforeRouteEnter(to) {
      if ('from_result' in to.query) {
        to.query = StripQuery(to.query, 'from_result');
        return to;
      }
    },
    mounted() {
      this.submitResult();
    }
  }
</script>

<style scoped lang="scss">
.issue-summary-button-wrapper {
  font-size: 0.8em;
  text-align: center;
}
.overview-item {
  cursor: pointer;
  transition: opacity 0.2s ease;
  &:hover {
    opacity: 1 !important;
  }
}

.submit-result-indicator {
  padding: 0 map-get($spacers, 4);
  margin-bottom: map-get($spacers, 2);
  opacity: 0.7;

  .submit-message {
    font-weight: 600;
    font-size: 0.8em;
    text-transform: uppercase;
  }
}
</style>

<style lang="scss">
  .calendly-brand-bg {
    background-color: #fbfcfd !important;
  }
  .appointment-scheduler-bubble {
    &-inner {
      display: flex;
      align-items: center;
      @include std-box-shadow(4, 10, map-get($theme-colors, 'success'), true);
    }
    &-avatar {
      border-radius: 99px;
      height: 50px;
    }
  }
</style>