<template>
  <interview-layout :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div class="">
      <!-- title above form card -->
      <h1 class="px-4 pb-3 fade-in-up-enter-active animation-slowest max-100 text-primary">Stimuleringslening</h1>
      <h5 class="px-4 pb-3 fade-in-up-enter-active animation-slowest delay-200 max-100">Aangeboden door het SNN</h5>
      <div class="d-flex flex-column flex-lg-row justify-content-center">

        <!-- form 1 card -->
        <base-card inner-class="container-narrow w-100" color="alternate" :outer-padding="true">
          <base-form @submit="onSubmit">

            <h6 class="pb-3 mt-3 max-100">Stimuleringslening Midden-Groningen van het SNN</h6>
            <div class="small mb-4">
                <checkmark-item class="mb-2" icon="info-circle-fill">
                  Heeft u geen overwaarde of gebruikt je die liever niet? Dan kun je gebruik maken van de stimuleringsregeling.
                </checkmark-item>
                <checkmark-item class="mb-2" icon="info-circle-fill">
                  Lening met een lage rente via het Stimuleringsfonds Volkshuisvesting (SVn).
                </checkmark-item>
                <checkmark-item class="mb-2" icon="info-circle-fill">
                  Consumptieve lening (tot &euro; 10.000) met een looptijd tot 15 jaar of hypothecaire lening (vanaf &euro; 10.000) met een looptijd tot 30 jaar.
                </checkmark-item>
                <checkmark-item class="mb-2" icon="exclamation-circle-fill" icon-color="warning">
                  De offerte voor de maatregelen mag nog niet getekend zijn.
                </checkmark-item>
                <checkmark-item class="mb-2" icon="hourglass-split" icon-color="warning">
                  Aanvragen kan tot t/m 31 december 2024.
                </checkmark-item>
                <i class="mb-2">
                  Meer informatie over de Stimuleringslening Midden-Groningen vindt u op <a href="https://www.snn.nl/subsidies-voor-particulieren/stimuleringslening-gemeente-midden-groningen-2019" target="_blank">snn.nl</a>.
                </i>
            </div>

            <h6 class="pb-3 mt-3 max-100">Waarvoor kunt u deze lening gebruiken?</h6>
            <div class="small mb-4">
              <checkmark-item class="mb-2" icon="check-circle-fill">
                Energiebesparende maatregelen treffen.
              </checkmark-item>
              <checkmark-item class="mb-2" icon="check-circle-fill">
                Je huis comfortabeler en toekomstbestendiger maken.
              </checkmark-item>
              <checkmark-item class="mb-2" icon="check-circle-fill">
                Het levensloop bestendig maken van de woning.
              </checkmark-item>
              <checkmark-item class="mb-2" icon="check-circle-fill">
                Het onderhoud plegen aan je woning.
              </checkmark-item>
              <checkmark-item class="mb-2" icon="check-circle-fill">
                Asbesthoudende dakbedekking vervangen.
              </checkmark-item>
            </div>

            <base-input
              class="mt-5 "
              type="radiobuttons" :options="yesNoInfo"
              name="IncomeOver125pct"
              v-model:value="lead.financing.stimuleringslening.WantStimuleringsleningMG" :validator="v$.financing.stimuleringslening.WantStimuleringsleningMG">
              Wilt u gebruik maken van de Stimuleringsregeling?
            </base-input>

          </base-form>
        <!-- form card end -->
        </base-card>
      
      <!-- cards wrapper end -->
      </div>
      <button-row class="mt-2 flex-row-reverse">
        <base-button class="ms-auto" icon="bi-arrow-right" @click="onSubmit" :disabled="v$.financing.stimuleringslening.$invalid">Volgende</base-button>
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"
  import BaseCard from '@/components/BaseCard.vue'
  import BaseForm from "@/components/BaseForm.vue"
  import BaseInput from "@/components/BaseInput.vue"
  import BaseButton from "@/components/BaseButton.vue"
  import ButtonRow from "@/components/ButtonRow.vue"
  // import VariablePill from "@/components/VariablePill.vue"
  import CheckmarkItem from "@/components/CheckmarkItem.vue"
  // import IssueFeedback from "@/components/IssueFeedback.vue"

  import { useAllStores } from "@/helpers/UseAllStores"
  import { CommonComposable } from "@/helpers/CommonComposable"
  import { FlowService } from "@/services/FlowService"
  import { civilStatusOptions, ageBrackets, getSocialMinimum } from "@/services/SocialMinimumService"
  import { getAnnualMinimumWage, getMonthlyMinimumWage } from "@/helpers/MinimumWage"

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      BaseForm,
      BaseInput,
      BaseButton,
      ButtonRow,
      // VariablePill,
      CheckmarkItem,
      // IssueFeedback
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    data() {
      return {
        yesNo: [
          {
            label: 'Ja',
            value: true
          },
          {
            label: 'Nee',
            value: false
          },
        ],
        yesNoDontKnow: [
          {
            label: 'Ja',
            value: 'Ja'
          },
          {
            label: 'Nee',
            value: 'Nee'
          },
          {
            label: 'Weet ik niet',
            value: 'Weet ik niet'
          }
        ],
        moreLess: [
          {
            label: '- Lager',
            value: false
          },
          {
            label: 'Hoger +',
            value: true
          },
        ],
        yesNoInfo: [
          {
            label: 'Ja',
            value: 'Ja'
          },
          {
            label: 'Nee',
            value: 'Nee'
          },
          {
            label: 'Ik wil meer informatie',
            value: 'Ik wil meer informatie'
          }
        ],
        civilStatusOptions: civilStatusOptions,
        ageBrackets: ageBrackets
      };
    },
    computed: {
      socialMinimum() {
        const nipFields = this.lead.financing.nip;
        return getSocialMinimum(nipFields.AgeBracket, nipFields.AgeBracketPartner, nipFields.CivilStatus,  nipFields.HasChildren);
      },
      socialMinimum125() {
        // multiply by 1.25, round to 2 decimals, replace dot with comma
        return (this.socialMinimum * 1.25).toFixed(2).replace('.', ',');
      },
      monthlyWage125Pct() {
        let maxWage = getMonthlyMinimumWage(2023, 2, 1.25, true, 0);
        return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(maxWage);
      },
      annualWage125Pct() {
        let maxWage = getAnnualMinimumWage(2023, 1.25, true, 0);
        return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(maxWage);
      }
    },
    methods: {
      touchAndValidate() {
        this.v$.financing.warmtefonds.$touch();
        return !this.v$.financing.warmtefonds.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
      onPrev() {
        FlowService.pushPrev(this);
      }
    }
  }
</script>

<style lang="scss">
</style>