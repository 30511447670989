<template>
  <interview-layout :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div class="container-narrow">
      <!-- title above form card -->
      <h1 class="px-4 text-primary fade-in-up-enter-active animation-slowest">{{ greeting }}</h1>
      <h5 class="px-4 pb-3 fade-in-up-enter-active animation-slowest delay-200 max-100">Zo weten of u in aanmerking komt.</h5>
      <!-- form card -->
      <base-card inner-class="container-narrow w-100" color="alternate" :outer-padding="true">
        <base-form @submit="onSubmit">
          <!-- inputs -->
          <div class="d-sm-flex gap-5">
            <base-input name="PostalCode" placeholder="0000AA"
              v-model:value="lead.address.PostalCode" :validator="v$.address.PostalCode" @update:value="fetchAddress" :debounce="500">
              Postcode
            </base-input>
            <base-input name="StreetNumber" fieldWidth="150px"
              v-model:value="lead.address.StreetNumber" :validator="v$.address.StreetNumber" @update:value="fetchAddress" :debounce="500">
              Huisnummer
            </base-input>
          </div>
          
          <!-- <transition name="fade-in-up">
            <div v-show="lead.address.PostalCode && lead.address.StreetNumber">
              <base-input name="Street" placeholder="Straatnaam" :disabled="bagMatch"
                v-model:value="lead.address.Street" :validator="v$.address.Street">
                Straatnaam
              </base-input>

              <base-input name="City" placeholder="Plaatsnaam" :disabled="bagMatch"
                v-model:value="lead.address.City" :validator="v$.address.City">
                Plaatsnaam
              </base-input>
            </div>
          </transition> -->

          <transition name="fade-in-up">
            <base-card v-show="true" color="light" class="mt-2" inner-class="d-flex" :stretch-inner="true">
              <!-- map pin icon -->
              <i :class="addressCardClass"></i>
              <div>
                <span key="addr_display" class="fw-600" v-show="addressStatus == 'eligible' || addressStatus == 'ineligible'">
                  {{lead.address.Street}} {{ lead.address.StreetNumber }}, {{ lead.address.City }}
                </span>
                <span key="loading" class="text-muted mt-2" v-show="addressStatus == 'busy'">
                  Adres controleren...
                </span>
                <span key="invalid" class="text-danger mt-2" v-show="addressStatus == 'invalid'">
                  Wij kunnen geen gegevens vinden over dit adres. Controleer de postcode en het huisnummer.
                </span>
                <span key="empty" class="text-muted fst-italic mt-2" v-show="addressStatus == 'incomplete'">
                  Vul uw postcode en huisnummer in.
                </span>
              </div>
            </base-card>
          </transition>

          <!-- buttons -->
          <button-row class="mt-4">
            <base-button class="ms-auto" icon="bi-arrow-right" type="submit" :disabled="v$.address.$invalid || uiStore.addressFetchBusy">Volgende</base-button>
          </button-row>
        </base-form>
      <!-- form card end -->
      </base-card>
      <button-row class="mt-2">
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"
  import BaseCard from '@/components/BaseCard.vue'
  import BaseForm from "@/components/BaseForm.vue"
  import BaseInput from "@/components/BaseInput.vue"
  import BaseButton from "@/components/BaseButton.vue"
  import ButtonRow from "@/components/ButtonRow.vue"

  import { useAllStores } from "@/helpers/UseAllStores"
  import { CommonComposable } from "@/helpers/CommonComposable"
  import { FlowService } from "@/services/FlowService"

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      BaseForm,
      BaseInput,
      BaseButton,
      ButtonRow
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    computed: {
      greeting() {
        if (this.uiStore.isExistingCustomer && !this.v$.address.$invalid) {
          return 'Kloppen deze gegevens?';
        } else {
          return 'Wat is uw adres?';
        }
      },
      addressComplete() {
        return (this.lead.address.PostalCode && this.lead.address.StreetNumber);
      },
      isInvalidAddress() {
        return !this.lead.address.id && !this.uiStore.addressFetchBusy && this.addressComplete;
      },
      addressStatus() {
        if (this.lead.address.id && this.lead.address.eligible) {
          return 'eligible';
        } else if (this.lead.address.id && !this.lead.address.eligible) {
          return 'ineligible';
        } else if (this.uiStore.addressFetchBusy) {
          return 'busy';
        } else if (this.isInvalidAddress) {
          return 'invalid';
        } else {
          return 'incomplete';
        }
      },
      addressCardClass() {
        return {
          'bi bi-geo-alt-fill me-2': true,
          'text-primary': this.addressStatus == 'eligible',
          'text-warning': this.addressStatus == 'ineligible',
          'text-muted': this.addressStatus == 'busy' || this.addressStatus == 'incomplete',
          'text-danger': this.addressStatus == 'invalid'
        }
      }
    },
    methods: {
      fetchAddress() {
        if (this.addressStore.diffLeadAddress()) {
          // reset
          this.addressStore.clearAddressData(true);
          // if PostalCode and StreetNumber are filled, and PostalCode >= 6 chars
          if (this.lead.address.PostalCode && this.lead.address.StreetNumber && this.lead.address.PostalCode.length >= 6) {
            // fetch address
            this.addressStore.fetchAddress(this.lead.address.PostalCode, this.lead.address.StreetNumber);
          } else {
            console.log('fetchAddress: PostalCode and StreetNumber are not filled or PostalCode < 6 chars');
          }
        } else {
          console.log('Address not different.');
        }
      },
      touchAndValidate() {
        this.v$.address.$touch();
        return !this.v$.address.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
      onPrev() {
        FlowService.pushPrev(this);
      }
    },
    mounted() {
      this.fetchAddress();
    }
  }
</script>

<style lang="scss">
</style>