<template>
  <interview-layout :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div class="">
      <!-- title above form card -->
      <h1 class="px-4 pb-3 fade-in-up-enter-active animation-slowest max-100 text-primary">Subsidieregelingen</h1>
      <h5 class="px-4 pb-3 fade-in-up-enter-active animation-slowest delay-200 max-100">{{ lead.NIP_address ? 'NIP subsidie' : 'Gemeentelijke regelingen' }}</h5>
      <div class="d-flex flex-column flex-lg-row justify-content-center">

        <!-- form 1 card -->
        <base-card inner-class="container-narrow w-100" color="alternate" :outer-padding="true">
          <base-form @submit="onSubmit">

          <div v-show="lead.NIP_address">
            <h6 class="pb-3 mt-3 max-100">Verduurzaming en isolatie woningen voor huiseigenaren &euro; 1.000 tot &euro; 2.750</h6>
            <div class="small mb-4">
                <checkmark-item class="mb-2" icon="info-circle-fill">
                  Tot &euro; 1.750 per woning voor maatregelen voor verduurzaming.
                </checkmark-item>
                <checkmark-item class="mb-2" icon="info-circle-fill">
                  Tot &euro; 1.000 per woning voor isolatie.
                </checkmark-item>
                <checkmark-item class="mb-2" icon="info-circle-fill">
                  Voor huishoudens die niet m&eacute;&eacute;r verdienen dan 125% van het minimumloon.
                </checkmark-item>
            </div>
          </div>

          <div v-show="!lead.NIP_address">
            <div class="small mb-4">
                <checkmark-item class="mb-2" icon="info-circle-fill">
                  Sommige gemeenten bieden subsidie vanuit het Nationaal Isolatie Programma (NIP).
                </checkmark-item>
                <checkmark-item class="mb-2" icon="info-circle-fill">
                  Nog niet alle gemeenten hebben een regeling voor deze subsidie, maar we vragen wel alvast relevante gegevens.
                </checkmark-item>
                <checkmark-item class="mb-2" icon="info-circle-fill">
                  Voor huishoudens die niet m&eacute;&eacute;r verdienen dan 125% van het minimumloon.
                </checkmark-item>
            </div>
          </div>
            
            <!-- card with 125% of social minimum -->
            <base-card inner-class="small">
              <strong class="fw-500">
                U mocht in {{ parseInt(new Date().getFullYear()) - 1 }} maximaal 125% van het minimumloon verdienen. Dat komt neer op ca.:
              </strong>
              <div class="mt-3 fw-500">
                <span class="fw-600 fs-5 text-info">
                  {{ monthlyWage125Pct }}
                </span>
                per maand (bruto)
              </div>
              <div class="mt-3 fw-500">
                <span class="fw-600 fs-5 text-info">
                  {{ annualWage125Pct }}
                </span>
                per jaar (bruto)
              </div>
              <div class="mt-3">
                <i>Dit is het totale <strong class="fw-600">bruto</strong> inkomen van uw <strong class="fw-600">huishouden</strong> bedragen, exclusief vakantiegeld.</i>
              </div>
              <div class="mt-3" v-show="lead.hasIncomeCategory">
                Op basis van het opgegeven netto inkomen schatten wij in dat u {{ lead.financing.income.WML_125pct ? 'minder' : 'meer' }} dan 125% van het minimumloon (bruto) verdient.
              </div>
            </base-card>

            <base-input
              class="mt-5 "
              type="radiobuttons" :options="moreLess"
              name="IncomeOver125pct"
              v-model:value="lead.financing.nip.IncomeOver125pct" :validator="v$.financing.nip.IncomeOver125pct">
              Was uw <strong class="fw-600">bruto</strong> inkomen ({{ parseInt(new Date().getFullYear()) - 1 }}) hoger of lager dan de bovenstaande bedragen?
            </base-input>

            <div class="mt-3 mb-5 small">
              <i><strong class="fw-600">Weet u het niet precies?</strong> Maak dan een schatting. We kijken later nogmaals naar uw inkomen v&oacute;&oacute;r we overgaan tot een offici&euml;le aanvraag.</i>
            </div>

            <base-card inner-class="small" color="secondary" v-show="lead.NIP_address">
              <h6>Belangrijkste voorwaarden</h6>
              <checkmark-item class="mb-2" icon="check-circle-fill" :icon-color="lead.financing.nip.IncomeOver125pct === false ? 'success' : 'warning'">
                Het huishouden had in het voorgaande jaar een inkomen dat niet hoger is 125% van het wettelijk minimumloon.
              </checkmark-item>
              <checkmark-item class="mb-2" icon="check-circle-fill" :icon-color="lead.address.BAG_oorspronkelijkBouwjaar__c > 1900 && lead.address.BAG_oorspronkelijkBouwjaar__c < 1994 ? 'success' : 'warning'">
                Bouwjaar v&oacute;&oacute;r <strong class="fw-600">1994</strong> <span v-show="lead.address.BAG_oorspronkelijkBouwjaar__c">(volgens Kadaster: <strong class="fw-600">{{ lead.address.BAG_oorspronkelijkBouwjaar__c }}</strong>)</span>
              </checkmark-item>
              <checkmark-item class="mb-2" icon="check-circle-fill" :icon-color="lead.EP_D_or_lower || !lead.address.Energielabel__c ? 'success' : 'warning'">
                Energielabel <strong class="fw-600">D</strong> of lager
                <span v-show="lead.address.Energielabel__c">(volgens onze gegevens: <strong class="fw-600">{{ lead.address.Energielabel__c }}</strong>)</span>
              </checkmark-item>
              <checkmark-item class="mb-2" icon="check-circle-fill" :icon-color="'info'">
                De WOZ-waarde van de woning is <strong class="fw-600">&euro; 249.000</strong> of lager.
              </checkmark-item>
              <div class="mt-2 fst-italic">
                De regeling is te vinden op <a href="https://lokaleregelgeving.overheid.nl/CVDR712667/1?page=3" target="_blank">lokaleregelgeving.overheid.nl</a>.
              </div>
            </base-card>

            <base-input
              class="mt-5"
              v-if="lead.financing.nip.IncomeOver125pct === false"
              type="radiobuttons" :options="yesNo"
              name="PermissionToRequestNip"
              v-model:value="lead.financing.nip.PermissionToRequestNip" :validator="v$.financing.nip.PermissionToRequestNip">
              U komt mogelijk in aanmerking. Mogen wij u helpen bij de subsidieaanvraag?
            </base-input>

          </base-form>
        <!-- form card end -->
        </base-card>
      
      <!-- cards wrapper end -->
      </div>
      <button-row class="mt-2 flex-row-reverse">
        <base-button class="ms-auto" icon="bi-arrow-right" @click="onSubmit" :disabled="v$.financing.nip.$invalid">Volgende</base-button>
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"
  import BaseCard from '@/components/BaseCard.vue'
  import BaseForm from "@/components/BaseForm.vue"
  import BaseInput from "@/components/BaseInput.vue"
  import BaseButton from "@/components/BaseButton.vue"
  import ButtonRow from "@/components/ButtonRow.vue"
  // import VariablePill from "@/components/VariablePill.vue"
  import CheckmarkItem from "@/components/CheckmarkItem.vue"
  // import IssueFeedback from "@/components/IssueFeedback.vue"

  import { useAllStores } from "@/helpers/UseAllStores"
  import { CommonComposable } from "@/helpers/CommonComposable"
  import { FlowService } from "@/services/FlowService"
  import { civilStatusOptions, ageBrackets, getSocialMinimum } from "@/services/SocialMinimumService"
  import { getAnnualMinimumWage, getMonthlyMinimumWage } from "@/helpers/MinimumWage"

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      BaseForm,
      BaseInput,
      BaseButton,
      ButtonRow,
      // VariablePill,
      CheckmarkItem,
      // IssueFeedback
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    data() {
      return {
        yesNo: [
          {
            label: 'Ja',
            value: true
          },
          {
            label: 'Nee',
            value: false
          },
        ],
        yesNoDontKnow: [
          {
            label: 'Ja',
            value: 'Ja'
          },
          {
            label: 'Nee',
            value: 'Nee'
          },
          {
            label: 'Weet ik niet',
            value: 'Weet ik niet'
          }
        ],
        moreLess: [
          {
            label: '- Lager',
            value: false
          },
          {
            label: 'Hoger +',
            value: true
          },
        ],
        civilStatusOptions: civilStatusOptions,
        ageBrackets: ageBrackets
      };
    },
    computed: {
      socialMinimum() {
        const nipFields = this.lead.financing.nip;
        return getSocialMinimum(nipFields.AgeBracket, nipFields.AgeBracketPartner, nipFields.CivilStatus,  nipFields.HasChildren);
      },
      socialMinimum125() {
        // multiply by 1.25, round to 2 decimals, replace dot with comma
        return (this.socialMinimum * 1.25).toFixed(2).replace('.', ',');
      },
      monthlyWage125Pct() {
        let maxWage = getMonthlyMinimumWage(2023, 2, 1.25, true, 0);
        return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(maxWage);
      },
      annualWage125Pct() {
        let maxWage = getAnnualMinimumWage(2023, 1.25, true, 0);
        return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(maxWage);
      }
    },
    methods: {
      touchAndValidate() {
        this.v$.ownership.$touch();
        return !this.v$.ownership.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
      onPrev() {
        FlowService.pushPrev(this);
      },
      presetHigherLower() {
        if (this.lead.hasIncomeCategory) {
          this.lead.financing.nip.IncomeOver125pct = !this.lead.financing.income.WML_125pct;
        }
      }
    },
    mounted() {
      this.presetHigherLower();
    }
  }
</script>

<style lang="scss">
</style>