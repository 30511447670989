<template>
  <div class="soft-flex">
    <div>
      <i :class="`bi bi-${this.icon} text-${this.iconColor}`"></i>
    </div>
    <div class="ms-2">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckmarkItem',
  props: {
    icon: {
      type: String,
      default: 'check-circle-fill'
    },
    iconColor: {
      type: String,
      default: 'success'
    }
  }
}
</script>

<style scoped="true">
.soft-flex {
  display: flex;
}
</style>