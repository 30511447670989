<template>
  <interview-layout :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div class="">
      <!-- title above form card -->
      <h1 class="px-4 pb-3 fade-in-up-enter-active animation-slowest max-100 text-primary">De Klusbus</h1>
      <h5 class="px-4 pb-3 fade-in-up-enter-active animation-slowest delay-200 max-100">Praktische hulp bij kleine maatregelen</h5>
      <div class="d-flex flex-column flex-lg-row justify-content-center">

        <!-- form 1 card -->
        <base-card inner-class="container-narrow w-100" color="alternate" :outer-padding="true">
          <base-form @submit="onSubmit">

            <h6 class="pb-3 mt-3 max-100">Gratis hulp en materiaal voor kleine verbeteringen</h6>
            <div class="small mb-4">
                <checkmark-item class="mb-2" icon="info-circle-fill">
                  Met kleine aanpassingen kun je veel energie besparen. Denk hierbij aan radiatorfolie, tochtstrips, ledlampen en het afstellen van de cv-ketel.
                </checkmark-item>
                <checkmark-item class="mb-2" icon="info-circle-fill">
                  Ziet je op tegen het plaatsen van radiatorfolie? Of weet je niet waar je het beste tochtstrips kan bevestigen? De Klusbus helpt je graag.
                </checkmark-item>
                <checkmark-item class="mb-2" icon="info-circle-fill">
                  Het materiaal en het werk is helemaal gratis in de gemeente Midden-Groningen!
                </checkmark-item>
                <checkmark-item class="mb-2" icon="info-circle-fill">
                  Zo kunt u zich focussen op de grote maatregelen.
                </checkmark-item>
                <i class="mb-2">
                  Meer informatie vindt u op <a href="https://www.jouwbespaarcoach.com/klusbus" target="_blank">jouwbespaarcoach.com/klusbus</a>.
                </i>
            </div>
            
            <base-input
              class="mt-5"
              type="radiobuttons" :options="yesNo"
              name="WantPracticalHelp"
              v-model:value="lead.practicalHelp.WantPracticalHelp" :validator="v$.practicalHelp.WantPracticalHelp">
              Wilt u gratis hulp bij kleine energiebesparende maatregelen?
            </base-input>

          </base-form>
        <!-- form card end -->
        </base-card>
      
      <!-- cards wrapper end -->
      </div>
      <button-row class="mt-2 flex-row-reverse">
        <base-button class="ms-auto" icon="bi-arrow-right" @click="onSubmit" :disabled="v$.practicalHelp.$invalid">Volgende</base-button>
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"
  import BaseCard from '@/components/BaseCard.vue'
  import BaseForm from "@/components/BaseForm.vue"
  import BaseInput from "@/components/BaseInput.vue"
  import BaseButton from "@/components/BaseButton.vue"
  import ButtonRow from "@/components/ButtonRow.vue"
  // import VariablePill from "@/components/VariablePill.vue"
  import CheckmarkItem from "@/components/CheckmarkItem.vue"
  // import IssueFeedback from "@/components/IssueFeedback.vue"

  import { useAllStores } from "@/helpers/UseAllStores"
  import { CommonComposable } from "@/helpers/CommonComposable"
  import { FlowService } from "@/services/FlowService"
  import { civilStatusOptions, ageBrackets } from "@/services/SocialMinimumService"

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      BaseForm,
      BaseInput,
      BaseButton,
      ButtonRow,
      // VariablePill,
      CheckmarkItem,
      // IssueFeedback
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    data() {
      return {
        yesNo: [
          {
            label: 'Ja',
            value: true
          },
          {
            label: 'Nee',
            value: false
          },
        ],
        yesNoDontKnow: [
          {
            label: 'Ja',
            value: 'Ja'
          },
          {
            label: 'Nee',
            value: 'Nee'
          },
          {
            label: 'Weet ik niet',
            value: 'Weet ik niet'
          }
        ],
        moreLess: [
          {
            label: '- Lager',
            value: false
          },
          {
            label: 'Hoger +',
            value: true
          },
        ],
        yesNoInfo: [
          {
            label: 'Ja',
            value: 'Ja'
          },
          {
            label: 'Nee',
            value: 'Nee'
          },
          {
            label: 'Ik wil meer informatie',
            value: 'Ik wil meer informatie'
          }
        ],
        civilStatusOptions: civilStatusOptions,
        ageBrackets: ageBrackets
      };
    },
    computed: {
      
    },
    methods: {
      touchAndValidate() {
        this.v$.practicalHelp.$touch();
        return !this.v$.practicalHelp.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
      onPrev() {
        FlowService.pushPrev(this);
      }
    }
  }
</script>

<style lang="scss">
</style>